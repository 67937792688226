import {Fab, makeStyles} from "@material-ui/core";
import {blue, green} from "@material-ui/core/colors";
import React, {useCallback, useEffect, useState} from "react";
import { RouteComponentProps } from "react-router";
import Fetcher, { URLBuild } from "../../services/fetcher";
import {TenantParams} from "../tenant";
import {Add, Refresh} from "@material-ui/icons";
import type {IOrganisation} from "../../interfaces";
import Organisation from "../../components/organisation";
import OrganisationDialog from "../../components/organisation-dialog";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flex: 1,
        height: '100%',
        flexDirection: 'column'
    },
    addCollaborator: {
        backgroundColor: green['500'],
        color: 'dark'
    },
    refresh: {
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(2),
        backgroundColor: blue['500']
    }
}));

const OrganisationPage = ({match: {params: {id: tenantId}}}: RouteComponentProps<TenantParams>) => {
    const classes = useStyles();
    const [organisation, setOrganisation] = useState<IOrganisation | undefined>(undefined);
    const [modal, setModal] = useState<{open: boolean, data: IOrganisation | undefined}>({open: false, data: undefined});

    const getData = useCallback(() => {
        Fetcher.get(URLBuild('/organisation'), tenantId)
        .then((res) => setOrganisation(res.body));
    }, [tenantId]);

    useEffect(() => getData(), [getData, tenantId]);

    return (
        <div className={classes.root}>
            {organisation && <Organisation organisation={organisation} onSelect={(e) => setModal({open: true, data: e.data as IOrganisation})} />}
            {modal.open && !!modal.data && <OrganisationDialog organisation={modal.data} open={true} onClose={() => {setModal({open: false, data: undefined});getData()}} />}
            <Fab onClick={getData} className={classes.refresh}><Refresh /></Fab>
        </div>
    );
}

export default OrganisationPage;
