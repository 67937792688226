import { useAuthentication } from "../contexts/AuthenticationContext";
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom';
import Login from "../routes/login";
import Home from "../routes/home";
import Tenant from "../routes/tenant";
import Consent from "../routes/consent";

export interface RouteInterface extends RouteProps {
    name: string
    protected?: boolean
}

const routes: RouteInterface[] = [
    {
        name: 'login',
        path: '/login',
        component: Login,
    },
    {
        name: 'home',
        path: '/home',
        component: Home,
        protected: true
    },
    {
        name: 'tenant',
        path: '/tenants/:id',
        component: Tenant,
        protected: true
    },
    {
        name: 'consent',
        path: '/consent',
        component: Consent,
        protected: true
    }
]

const Router = () => {
    const auth = useAuthentication();
    const usedRoutes = routes.filter(x => (x.protected ?? false) === auth.isLogged);

    return (
        <Switch>
            {usedRoutes.map(route => <Route key={route.name} {...route} />)}
            <Redirect to={auth.isLogged ? (auth.isNahal ? '/home' : '/consent') : '/login'} />
        </Switch>
    );
}

export default Router;