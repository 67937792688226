import React, {ChangeEvent, useEffect, useState} from "react";
import {Divider, makeStyles, TextField} from "@material-ui/core";
import {useForm} from "../index";

export interface FormAddressModelProps {
    id: string
}

const requiredFields = ['title'];

const useStyles = makeStyles((theme) => ({
    control: {
        margin: theme.spacing(1)
    },
}));

const formModel: {[key: string]: string} = {
    title: 'Titre',
    country: 'Pays',
    state: 'état/région',
    zipcode: 'Zip',
    city: 'Ville',
    street: 'Rue',
    complementary: 'Informations en +'
};

const FormAddressModel = ({id}: FormAddressModelProps) => {
    const classes = useStyles();
    const form = useForm();
    const [address, setAddress] = useState<{[key: string]: string}>(form.form[id] ?? {});

    useEffect(() => {
        if (requiredFields.every(req => address[req] !== undefined && address[req] !== ''))
            form.update(id, address);
    }, [address, id, form]);

    const onChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setAddress((prev: any) => ({...prev, [e.target.id]: e.target.value}));
    }

    return (
        <>
            <h3>{form.model[id].label}</h3>
            <Divider />
            {Object.keys(formModel).map((key) => (
                <TextField
                    label={formModel[key]}
                    key={key}
                    id={key}
                    onChange={onChange}
                    className={classes.control}
                    value={address[key] ?? ''}
                    required={requiredFields.findIndex(e => e === key) >= 0}
                />
            ))}
        </>
    );
}

export default FormAddressModel;