import {DataGrid, GridRowSelectedParams} from "@material-ui/data-grid";
import {Institution} from "../../interfaces";

const InstitutionGrid = ({institutions, onSelect}: {institutions: Institution[], onSelect?: (e: GridRowSelectedParams) => void}) => {
    console.log(institutions);
    return (
        <DataGrid
            columns={[
                { field: 'id', headerName: 'Id', width: 300 },
                { field: 'name', headerName: 'Name', width: 300 },
                { field: 'siret', headerName: 'Siret', width: 150 },
                { field: 'lemonwayAccountId', headerName: 'Lemonway Account Id', width: 300 },
                { field: 'vatNumber', headerName: 'Numéro de TVA', width: 300 },
            ]}

            onRowSelected={onSelect}
            rows={institutions}
            rowCount={institutions.length}
        />
    );
}

export default InstitutionGrid;
