import { createContext, useContext, useEffect, useState } from "react";
import { msalConfig, silentRequest } from "../configs/MsalConfig";
import { AccountInfo, PublicClientApplication } from '@azure/msal-browser';

const nahalTenant = '8db23510-0f1e-4770-9caf-c14a4268303d';

export interface AuthenticationProps {
    account: AccountInfo | null
    isLogged: boolean
    getAccessToken: () => Promise<string>
    login: () => Promise<boolean>
    logout: () => void,
    isNahal: boolean
}

const authenticationDefault: AuthenticationProps = {
    account: null,
    isLogged: false,
    getAccessToken: () => Promise.resolve(''),
    login: () => Promise.resolve(false),
    logout: () => { },
    isNahal: false
}

export const msalClient = new PublicClientApplication(msalConfig);

export const AuthenticationContext = createContext<AuthenticationProps>(authenticationDefault);

export const AuthenticationContextProvider = ({ children }: any) => {
    const [account, setAccount] = useState<AccountInfo | null>(null);
    const [nahal, setNahal] = useState(false);
    const [loaded, setLoaded] = useState(false);

    const login = async (): Promise<boolean> => {
        try {
            const res = await msalClient.loginPopup(silentRequest);

            setNahal(res.account?.tenantId === nahalTenant);

            msalClient.setActiveAccount(res.account);
            setAccount(res.account);
            return true;
        } catch (e) {
            logout();
        }
        return false;
    }

    const logout = () => {
        localStorage.clear();
        setAccount(null);
    }

    useEffect(() => {
        const autologin = async () => {
            const accounts = msalClient.getAllAccounts();
            try {
                if (accounts.length === 0) throw new Error();
    
                msalClient.setActiveAccount(accounts[0]);
        
                const result = await msalClient.acquireTokenSilent(silentRequest);
    
                setNahal(result.account?.tenantId === nahalTenant);
                setAccount(result.account);
            } catch {
                localStorage.clear();
            } finally {
                setLoaded(true);
            }
        }
        autologin();
    }, []);

    const getAccessToken = async (): Promise<string> => {
        const result = await msalClient.acquireTokenSilent(silentRequest);

        return result.accessToken;
    }

    return (
        <AuthenticationContext.Provider value={{ isNahal: nahal, isLogged: account !== null, login, logout, account, getAccessToken }}>
            {loaded && children}
        </AuthenticationContext.Provider>
    );
}

export const useAuthentication = () => useContext(AuthenticationContext);