import React, {createContext, ReactNode, useContext, useEffect, useState} from "react";
import {FormModelObjectProps} from "../../interfaces";
import {Button, CircularProgress, makeStyles} from "@material-ui/core";

export interface FormProps {
    model: FormModelObjectProps
    form: {[key: string]: any}
    update: (id: string, value: any) => void
}

const tenantDefault: FormProps = {
    model: {},
    form: {},
    update: () => {}
}

export const FormContext = createContext<FormProps>(tenantDefault);

export interface FormModelProps {
    children: ReactNode
    model: FormModelObjectProps
    required: string[]
    onSubmit: (form: any) => void
    onRemove?: (form: any) => void
    value?: any
    loading?: boolean
    creation: boolean
}

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        margin: theme.spacing(1),
    },
    remove: {},
    submit: {
        display: 'block',
        marginLeft: 'auto',
    },
}));

const FormModel = ({ children, model, onSubmit, required, value, onRemove, loading, creation }: FormModelProps) => {
    const classes = useStyles();
    const [form, setForm] = useState(value ?? {});
    const [valid, setValid] = useState(false);

    const update = (id: string, value: string | number) =>
        setForm((prev: any) => prev[id] !== value ? ({...prev, [id]: value}) : prev);

    useEffect(() => {
        setValid(required.every(req => (model[req].creationOnly && !creation) || (form[req] !== undefined && form[req] !== '')));
    }, [form, setValid, required, creation, model]);

    const onClick = () =>
        onSubmit(form);

    return (
        <FormContext.Provider value={{ model, form, update }}>
            {children}
            <div className={classes.root}>
                {!!onRemove &&
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => onRemove(form)}
                    disabled={!valid}
                    className={classes.remove}
                >
                    Supprimer
                </Button>}
                {loading ?
                    <CircularProgress className={classes.submit} /> :
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onClick}
                        disabled={!valid}
                        className={classes.submit}
                    >
                        Valider
                    </Button>
                }
            </div>
        </FormContext.Provider>
    );
}

export const useForm = () => useContext(FormContext);

export default FormModel;