import { DialogProps } from "@material-ui/core";
import React, {useState} from "react";
import { useTenantId } from "../../contexts/TenantContext";
import Fetcher from "../../services/fetcher";
import {FormModelObjectProps, Institution} from "../../interfaces";
import FormDialog from "../forms/dialog";
import FormModel from "../forms";
import FormTextFieldModel from "../forms/textfield";
import {Alert} from "@material-ui/lab";
import FormAddressModel from "../forms/address";
import getConfig from "../../configs/AppConfig";

export interface InstitutionDialogProps extends Omit<DialogProps, 'children'> {
    institution?: Institution
}

export const InstitutionFormModel: FormModelObjectProps = {
    name: {label: 'Name'},
    siret: {label: 'Siret'},
    address: {label: 'Addresse'},
    lemonwayAccountId: {label: 'Lemonway - Account Id'},
    vatNumber: {label: 'Numéro de TVA'},
}

const requiredKeys = [ 'name', 'siret' ];

const InstitutionDialog = (props: InstitutionDialogProps) => {
    const [failed, setFailed] = useState(false);
    const tenantId = useTenantId();

    const checkKyc = (institution: Institution | undefined, result: Institution) => {
        if (!institution || institution.lemonwayAccountId !== result.lemonwayAccountId) {
            Fetcher.get(getConfig().ApiUrl + `/institutions/${result.id}/check-kyc`, tenantId)
                .then(() => props.onClose?.({}, 'escapeKeyDown'))
                .catch(() => setFailed(true));
        } else {
            props.onClose?.({}, 'escapeKeyDown');
        }
    }

    const submit = (form: any) => {
        setFailed(false);
        if (!!props.institution) {
            Fetcher.patch<Institution>(getConfig().ApiUrl + `/institutions/${props.institution.id}`, tenantId, JSON.stringify(form))
                .then((res) => checkKyc(props.institution, res.body))
                .catch(() => setFailed(true));
        } else {
            Fetcher.post<Institution>(getConfig().ApiUrl + '/institutions', tenantId, JSON.stringify(form))
                .then((res) => checkKyc(props.institution, res.body))
                .catch(() => setFailed(true));
        }
    }

    const remove = () => {
        Fetcher.delete(getConfig().ApiUrl + `/institutions/${props.institution?.id}`, tenantId)
            .then(() => props.onClose && props.onClose({}, 'escapeKeyDown'));
    }

    return (
        <FormDialog
            {...props}
            title={(!!props.institution ? "Mise à jour" : "Création") + " d'une institution"}
        >
            {failed && <Alert severity="error">Erreur lors de la création de l'établissement</Alert>}
            <FormModel creation={!props.institution} model={InstitutionFormModel} onRemove={props.institution ? remove : undefined} required={requiredKeys} onSubmit={submit} value={props.institution}>
                <FormTextFieldModel id={'name'} />
                <FormTextFieldModel id={'siret'} />
                <FormTextFieldModel id={'lemonwayAccountId'} />
                <FormTextFieldModel id={'vatNumber'} />
                {!props.institution && <FormAddressModel id={'address'} />}
            </FormModel>
        </FormDialog>
    );
}

export default InstitutionDialog;