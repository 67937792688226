import { useAuthentication } from "../../contexts/AuthenticationContext";
import { Card, CardContent, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { PublicClientApplication } from "@azure/msal-browser";
import getConfig from "../../configs/AppConfig";

const useClasses = makeStyles(() => ({
    root: {
        display: 'flex',
        flex: 1,
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    consent: {
        marginBottom: 16
    }
}));

const Consent = () => {
    const { account } = useAuthentication();
    const classes = useClasses();

    const AdminConsent = () => {
        const adminScope = "https://graph.microsoft.com/.default";
    
        window.location.href = `https://login.microsoftonline.com/${account?.tenantId}/v2.0/adminconsent?client_id=${getConfig().ApiClientId}&scope=${encodeURIComponent(adminScope)}&redirect_uri=${encodeURIComponent(`${window.location.origin}/consent`)}`;
    }

    const AdminFrontConsent = () => {
        window.location.href = `https://login.microsoftonline.com/${account?.tenantId}/v2.0/adminconsent?client_id=${getConfig().FrontClientId}&scope=${getConfig().ApiScope}&redirect_uri=${encodeURIComponent(`${window.location.origin}/consent`)}`;
    }

    const PluginConsent = async () => {
        const msalInstancePlug = new PublicClientApplication({auth: {clientId: getConfig().PluginClientId}});
        await msalInstancePlug.loginPopup({
            redirectUri: `${window.location.origin}/login`,
            scopes: [getConfig().ApiScope],
        });
    }

    return (
        <div className={classes.root}>
            <Card>
                <CardContent>
                    Bonjour {account?.name}
                    <p>
                        Afin d'integrer Nahal dans vos outils, merci de bien vouloir cliquer sur les liens suivants
                    </p>
                    <div>
                        <Button
                            className={classes.consent}
                            variant="contained"
                            color="primary"
                            onClick={AdminConsent}
                        >
                            Donner accès à Nahal
                        </Button>
                    </div>
                    <div>
                        <Button
                            className={classes.consent}
                            variant="contained"
                            color="primary"
                            onClick={AdminFrontConsent}
                        >
                            Donner accès à Nahal Front
                        </Button>
                    </div>
                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={PluginConsent}
                        >
                            Lier le plugin outlook
                        </Button>
                    </div>
                </CardContent>
            </Card>
        </div>
    )
}

export default Consent;