import {Fab, makeStyles} from "@material-ui/core";
import {blue, green} from "@material-ui/core/colors";
import React, {useCallback, useEffect, useState} from "react";
import { RouteComponentProps } from "react-router";
import Fetcher, { URLBuild } from "../../services/fetcher";
import {TenantParams} from "../tenant";
import {Add, Refresh} from "@material-ui/icons";
import {Institution, Institutions} from "../../interfaces";
import InstitutionGrid from "../../components/institution-grid";
import InstitutionDialog from "../../components/institution-dialog";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flex: 1,
        height: '100%',
        flexDirection: 'column'
    },
    addCollaborator: {
        backgroundColor: green['500'],
        color: 'dark'
    },
    fab: {
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(2),
        backgroundColor: green['500']
    },
    refresh: {
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(11),
        backgroundColor: blue['500']
    }
}));

const InstitutionsPage = ({match: {params: {id: tenantId}}}: RouteComponentProps<TenantParams>) => {
    const classes = useStyles();
    const [institutions, setInstitutions] = useState<Institution[]>([]);
    const [modal, setModal] = useState<{open: boolean, data: Institution | undefined}>({open: false, data: undefined});

    const getData = useCallback(() => {
        Fetcher.get<Institutions>(URLBuild('/institutions',
        {
            pageSize: -1
        }), tenantId)
        .then((res) => setInstitutions(res.body.institutions));
    }, [tenantId]);

    useEffect(() => getData(), [getData, tenantId]);

    return (
        <div className={classes.root}>
            <InstitutionGrid institutions={institutions} onSelect={(e) => setModal({open: true, data: e.data as Institution})} />
            {modal.open && <InstitutionDialog institution={modal.data} open={true} onClose={() => {setModal({open: false, data: undefined});getData()}} />}
            <Fab onClick={() => setModal({open: true, data: undefined})} className={classes.fab}><Add /></Fab>
            <Fab onClick={getData} className={classes.refresh}><Refresh /></Fab>
        </div>
    );
}

export default InstitutionsPage;
