import {DataGrid, GridRowSelectedParams} from "@material-ui/data-grid";
import {Collaborator} from '../../interfaces';

const Collaborators = ({collaborators, onSelect}: {collaborators: Collaborator[], onSelect?: (e: GridRowSelectedParams) => void}) => {
    return (
        <DataGrid
            columns={[
                { field: 'id', headerName: 'Id', width: 300 },
                { field: 'email', headerName: 'Email', width: 300 },
                { field: 'firstname', headerName: 'Firstname', width: 150 },
                { field: 'lastname', headerName: 'Lastname', width: 150 },
                { field: 'administration', headerName: 'Admin', width: 150 },
                { field: 'creator', headerName: 'Creator', width: 150 },
                { field: 'grossSalaryCts', headerName: 'GrossSalaryCts', width: 200 },
                { field: 'hierarchyLevel', headerName: 'HierarchyLevel', width: 200 },
                { field: 'hourlyRateCts', headerName: 'HourlyRateCts', width: 200 },
                { field: 'phone', headerName: 'Phone', width: 150 },
                { field: 'poste', headerName: 'Poste', width: 150 },
            ]}

            onRowSelected={onSelect}
            rows={collaborators}
            rowCount={collaborators.length}
        />
    );
}

export default Collaborators;
