import {Redirect, Route, Switch, useRouteMatch} from 'react-router-dom';
import React, {useEffect} from "react";
import {RouteInterface} from "../../router";
import CollaboratorsPage from "../collaborators";
import {RouteComponentProps} from "react-router";
import {useTenant} from "../../contexts/TenantContext";
import {makeStyles} from "@material-ui/core";
import Navigation, {DrawerWidth} from "../../components/navigation";
import InstitutionsPage from "../institutions";
import * as Path from "path";
import OrganisationPage from '../organisation';

export const tenantRoutes: RouteInterface[] = [
    {
        name: 'collaborators',
        path: 'collaborators',
        component: CollaboratorsPage
    },
    {
        name: 'institutions',
        path: 'institutions',
        component: InstitutionsPage
    },
    {
        name: 'organisation',
        path: 'organisation',
        component: OrganisationPage
    }
]

export interface TenantParams { id: string }

const useStyles = makeStyles({
    root: {
        flex: 1,
        height: '100vh',
        flexDirection: 'row',
        marginLeft: DrawerWidth
    },
    tenantId: {
        margin: 'auto'
    },
})

const Tenant = ({match: {params: {id: tenantId}}}: RouteComponentProps<TenantParams>) => {
    const classes = useStyles();
    const tenant = useTenant();
    const {path} = useRouteMatch();

    useEffect(() => tenant.setTenantId(tenantId), [tenant, tenantId]);

    return (
        <div className={classes.root}>
            <Navigation />
            <Switch>
                {tenantRoutes.map((route) =>(<Route key={route.name} {...route} path={Path.join(path, route.path as string)} />))}
                <Redirect to={Path.join(path, 'collaborators')} />
            </Switch>
        </div>
    );
}

export default Tenant;