import getConfig from "../configs/AppConfig";
import { silentRequest } from "../configs/MsalConfig";
import { msalClient } from "../contexts/AuthenticationContext";

export const URLBuild = (base: string, params?: any): string => {
    var url = new URL(getConfig().ApiUrl + base);

    if (!!params) {
        Object.keys(params).forEach(key => {
            if (Array.isArray(params[key])) {
                params[key].forEach((val: string) => {
                    url.searchParams.append(key + '[]', val);
                })
            } else {
                url.searchParams.append(key, params[key])
            }
        });
    }

    return url.toString();
}

export interface JsonProblem {
    type: string
    title: string
    detail: string
    instance: string
    errors: {[key: string]: [string]}
}

export interface FetcherResponse<T> {
    readonly headers: Headers;
    readonly ok: boolean;
    readonly redirected: boolean;
    readonly status: number;
    readonly statusText: string;
    readonly body: T
}

const Fetcher = {
    request: async <T = any>(method: string, path: string, body: any = null, headers: any = {}, tenantId: string = ''): Promise<FetcherResponse<T>> => {
        headers = Object.assign({}, headers, {
            Authorization: 'Bearer ' + (await msalClient.acquireTokenSilent(silentRequest)).accessToken,
            'Content-Type': 'application/json',
            ...(tenantId !== '' ? {'Tenant-Id': tenantId} : {})
        });

        const response = await fetch(path, { method, headers, body })
        const responseBody = response.status !== 204 ? await response.json() : {};

        const res = {
            headers: response.headers,
            ok: response.ok,
            redirected: response.redirected,
            status: response.status,
            statusText: response.statusText,
            body: responseBody
        };

        if (!res.ok) throw res;

        return res;
    },

    get: async <T = any>(path: string, tenantId: string = '', headers: any = {}): Promise<FetcherResponse<T>> => Fetcher.request<T>('GET', path, null, headers, tenantId),
    post: async <T = any>(path: string, tenantId: string = '', data: any = {}, headers: any = {}): Promise<FetcherResponse<T>> => Fetcher.request<T>('POST', path, data, headers, tenantId),
    put: async <T = any>(path: string, tenantId: string = '', data: any, headers: any = {}): Promise<FetcherResponse<T>> => Fetcher.request<T>('PUT', path, data, headers, tenantId),
    patch: async <T = any>(path: string, tenantId: string = '', data: any, headers: any = {}): Promise<FetcherResponse<T>> => Fetcher.request<T>('PATCH', path, data, headers, tenantId),
    delete: async <T = any>(path: string, tenantId: string = '', headers: any = {}): Promise<FetcherResponse<T>> => Fetcher.request<T>('DELETE', path, null, headers, tenantId)
};

export default Fetcher;
