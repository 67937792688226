import { createContext, useContext, useState } from "react";

export interface TenantProps {
    tenantId: string
    setTenantId: (id: string) => void
}

const tenantDefault: TenantProps = {
    tenantId: "",
    setTenantId: () => {}
}

export const TenantContext = createContext<TenantProps>(tenantDefault);

export const TenantContextProvider = ({ children }: any) => {
    const [tenantId, setTenantId] = useState('');

    return (
        <TenantContext.Provider value={{ tenantId, setTenantId }}>
            {children}
        </TenantContext.Provider>
    );
}

export const useTenant = () => useContext(TenantContext);
export const useTenantId = () => useTenant().tenantId;