import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router';
import App from './App';
import { AuthenticationContextProvider } from './contexts/AuthenticationContext';
import reportWebVitals from './reportWebVitals';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme, CssBaseline } from '@material-ui/core';
import { TenantContextProvider } from './contexts/TenantContext';

const history = createBrowserHistory();
const theme = createMuiTheme();

ReactDOM.render(
  <AuthenticationContextProvider>
    <TenantContextProvider>
      <Router history={history}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </Router>
    </TenantContextProvider>
  </AuthenticationContextProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
