import {DataGrid, GridRowSelectedParams} from "@material-ui/data-grid";
import {IOrganisation} from '../../interfaces';

const Organisation = ({organisation, onSelect}: {organisation: IOrganisation, onSelect?: (e: GridRowSelectedParams) => void}) => {
    return (
        <DataGrid
            columns={[
                { field: 'id', headerName: 'Id', width: 150 },
                { field: 'name', headerName: 'Nom', width: 150 },
                { field: 'kv', headerName: 'Kv', width: 300 },
                { field: 'legalForm', headerName: 'Forme légale', width: 300 },
                { field: 'capitalStockCts', headerName: 'Capital (cts)', type: 'number', width: 150 },
                { field: 'phone', headerName: 'Téléphone', width: 150 },
                { field: 'email', headerName: 'Email', type: 'email', width: 150 },
                { field: 'website', headerName: 'Website', width: 150 },
                { field: 'nafCode', headerName: 'Code Naf', width: 150 },
                { field: 'color', headerName: 'Code Couleur', width: 200 },
                { field: 'logoId', headerName: 'Id du logo', width: 150 },
                { field: 'goCardlessApiToken', headerName: 'GoCardless Api Token', width: 200 }
            ]}

            onRowSelected={onSelect}
            rows={[organisation]}
            rowCount={1}
        />
    );
}

export default Organisation;
