import {Button, Divider, Drawer, List, ListItem, makeStyles} from "@material-ui/core";
import {tenantRoutes} from "../../routes/tenant";
import React from "react";
import {useHistory} from 'react-router-dom';
import {useTenantId} from "../../contexts/TenantContext";

export const DrawerWidth = 200;

const useStyles = makeStyles({
    root: {
        flex: 1,
        maxWidth: DrawerWidth
    },
    route: {
        flex: 1,
        alignContent: 'center',
        fontSize: 18
    },
    back: {
        width: '100%'
    },
    tenant: {
        padding: 8
    }
});

const Navigation = () => {
    const classes = useStyles();
    const tenantId = useTenantId();
    const history = useHistory();

    return (
        <Drawer
            variant="permanent"
        >
            <div className={classes.root}>
                <Button className={classes.back} onClick={() => history.push('/home')}>Back</Button>
                <div className={classes.tenant}>Tenant {tenantId}</div>
                <Divider />
                <List style={{flex: 1}}>
                    {tenantRoutes.map((route) => (
                        <ListItem
                            button
                            onClick={() => history.push(route.path as string)}
                            key={route.name}
                            className={classes.route}
                        >
                            {route.name}
                        </ListItem>
                    ))}
                </List>
            </div>
        </Drawer>
    );
}

export default Navigation;