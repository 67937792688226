import { useAuthentication } from "../../contexts/AuthenticationContext";
import MicrosoftSignIn from '../../assets/microsoft-sign-in.svg';
import { makeStyles } from "@material-ui/core";
import { ReactSVG } from "react-svg";

const useStyles = makeStyles({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
    },
    image: {
        cursor: 'pointer'
    }
});

const Login = () => {
    const classes = useStyles();
    const auth = useAuthentication();

    return (
        <div className={classes.root}>
            <ReactSVG className={classes.image} alt="Sign in with microsoft" src={MicrosoftSignIn} onClick={auth.login} />
        </div>
    );
}

export default Login;