import { Configuration } from "@azure/msal-browser";
import getConfig from "./AppConfig";

export const msalConfig: Configuration = {
    auth: {
        clientId: getConfig().FrontClientId,
        redirectUri: `${window.location.origin}/login`,
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
    },
};

export const silentRequest = {
    scopes: [getConfig().ApiScope],
    prompt: 'select_account',
    forceRefresh: false,
};