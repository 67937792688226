import { DialogProps } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useState } from "react";
import { useTenantId } from "../../contexts/TenantContext";
import {Collaborator, FormModelObjectProps} from "../../interfaces";
import Fetcher from "../../services/fetcher";
import FormTextFieldModel from "../forms/textfield";
import FormSelectModel from "../forms/select";
import FormDialog from "../forms/dialog";
import FormModel from "../forms";
import getConfig from "../../configs/AppConfig";

export interface CollaboratorDialogProps extends Omit<DialogProps, 'children'> {
    collaborator?: Collaborator
}

export const CollaboratorFormModel: FormModelObjectProps = {
    email: {label: 'Addresse Email', type: 'email'},
    firstname: {label: 'Prénom'},
    lastname: {label: 'Nom'},
    phone: {label: 'Téléphone'},
    hierarchyLevel: {label: 'Niveau hierarchique', type: 'number'},
    poste: {label: 'Poste'},
    grossSalaryCts: {label: 'Salaire (en centimes)', type: 'number'},
    hourlyRateCts: {label: 'Taux horaires (en centimes)', type: 'number'},
    administration: {label: 'Niveau d\'administration', select: {values: {'None': 'Aucun', 'Admin': 'Administrateur', 'SuperAdmin': 'Super Administrateur'}}},
    institutionId: {label: 'Institution',select: {label: 'name',value: 'id',endpoint: '/institutions'}}
}

const requiredKeys = [ 'email', 'firstname', 'lastname', 'phone', 'hierarchyLevel', 'poste', 'grossSalaryCts', 'hourlyRateCts', 'administration', 'institutionId' ];

const CollaboratorDialog = (props: CollaboratorDialogProps) => {
    const [failed, setFailed] = useState(false);
    const tenantId = useTenantId();

    const submit = (form: any) => {
        setFailed(false);
        if (!!props.collaborator) {
            Fetcher.patch(getConfig().ApiUrl + `/collaborators/${props.collaborator.id}`, tenantId, JSON.stringify(form))
                .then(() => props.onClose && props.onClose({}, 'escapeKeyDown'))
                .catch(() => setFailed(true));
        } else {
            Fetcher.post(getConfig().ApiUrl + '/collaborators', tenantId, JSON.stringify(form))
                .then(() => props.onClose && props.onClose({}, 'escapeKeyDown'))
                .catch(() => setFailed(true));
        }
    }

    const remove = () => {
        Fetcher.delete(getConfig().ApiUrl + `/collaborators/${props.collaborator?.id}`, tenantId)
            .then(() => props.onClose && props.onClose({}, 'escapeKeyDown'));
    }

    return (
        <FormDialog
            {...props}
            title={(!!props.collaborator ? "Mise à jour" : "Création") + " d'un collaborateur"}
        >
            {failed && <Alert severity="error">Erreur lors de la mise à jour du collaborateur</Alert>}
            <FormModel creation={!props.collaborator} model={CollaboratorFormModel} onRemove={props.collaborator ? remove : undefined} required={requiredKeys} onSubmit={submit} value={props.collaborator}>
                <FormTextFieldModel id={'email'} />
                <FormTextFieldModel id={'firstname'} />
                <FormTextFieldModel id={'lastname'} />
                <FormTextFieldModel id={'phone'} />
                <FormTextFieldModel id={'hierarchyLevel'} />
                <FormTextFieldModel id={'poste'} />
                <FormTextFieldModel id={'grossSalaryCts'} />
                <FormTextFieldModel id={'hourlyRateCts'} />
                <FormSelectModel id={'administration'} />
                <FormSelectModel id={'institutionId'} />
            </FormModel>
        </FormDialog>
    );
}

export default CollaboratorDialog;