import React from "react";
import {makeStyles, TextField} from "@material-ui/core";
import {useForm} from "../index";

const useStyles = makeStyles((theme) => ({
    control: {
        margin: theme.spacing(1)
    }
}));

interface FormTextFieldModelProps {
    id: string
}

const FormTextFieldModel = ({id}: FormTextFieldModelProps) => {
    const classes = useStyles();
    const form = useForm();

    return (
        <TextField
            label={form.model[id].label}
            key={id}
            id={id}
            type={form.model[id].type}
            onChange={e => form.update(id, e.target.value as string)}
            className={classes.control}
            value={form.form[id] ?? ''}
            required
        />
    );
}

export default FormTextFieldModel;