import {Fab, makeStyles} from "@material-ui/core";
import {blue, green} from "@material-ui/core/colors";
import React, {useCallback, useEffect, useState} from "react";
import { RouteComponentProps } from "react-router";
import Collaborators from "../../components/collaborators";
import Fetcher, { URLBuild } from "../../services/fetcher";
import {TenantParams} from "../tenant";
import {Add, Refresh} from "@material-ui/icons";
import CollaboratorDialog from "../../components/collaborator-dialog";
import {Collaborator} from "../../interfaces";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flex: 1,
        height: '100%',
        flexDirection: 'column'
    },
    addCollaborator: {
        backgroundColor: green['500'],
        color: 'dark'
    },
    fab: {
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(2),
        backgroundColor: green['500']
    },
    refresh: {
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(11),
        backgroundColor: blue['500']
    }
}));

const CollaboratorsPage = ({match: {params: {id: tenantId}}}: RouteComponentProps<TenantParams>) => {
    const classes = useStyles();
    const [collaborators, setCollaborators] = useState<any[]>([]);
    const [modal, setModal] = useState<{open: boolean, data: Collaborator | undefined}>({open: false, data: undefined});

    const getData = useCallback(() => {
        Fetcher.get(URLBuild('/collaborators',
        {
            pageSize: -1
        }), tenantId)
        .then((res) => setCollaborators(res.body.collaborators));
    }, [tenantId]);

    useEffect(() => getData(), [getData, tenantId]);

    return (
        <div className={classes.root}>
            <Collaborators collaborators={collaborators} onSelect={(e) => setModal({open: true, data: e.data as Collaborator})} />
            {modal.open && <CollaboratorDialog collaborator={modal.data} open={true} onClose={() => {setModal({open: false, data: undefined});getData()}} />}
            <Fab onClick={() => setModal({open: true, data: undefined})} className={classes.fab}><Add /></Fab>
            <Fab onClick={getData} className={classes.refresh}><Refresh /></Fab>
        </div>
    );
}

export default CollaboratorsPage;
