import { DialogProps } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useState } from "react";
import { useTenantId } from "../../contexts/TenantContext";
import {IOrganisation, FormModelObjectProps} from "../../interfaces";
import Fetcher from "../../services/fetcher";
import FormTextFieldModel from "../forms/textfield";
import FormDialog from "../forms/dialog";
import FormModel from "../forms";
import getConfig from "../../configs/AppConfig";

export interface OrganisationDialogProps extends Omit<DialogProps, 'children'> {
    organisation: IOrganisation
}

export const OrganisationFormModel: FormModelObjectProps = {
    name: {label: 'Nom'},
    kv: {label: 'Kv'},
    legalForm: {label: 'Forme légale'},
    capitalStockCts: {label: 'Capital (cts)', type: 'number'},
    phone: {label: 'Téléphone'},
    email: {label: 'Email', type: 'email'},
    website: {label: 'Website'},
    nafCode: {label: 'Code Naf'},
    color: {label: 'Code Couleur'},
    logoId: {label: 'Id du logo'}
}

const OrganisationDialog = (props: OrganisationDialogProps) => {
    const [failed, setFailed] = useState(false);
    const tenantId = useTenantId();

    const submit = (form: any) => {
        setFailed(false);
        Fetcher.patch(getConfig().ApiUrl + '/organisation', tenantId, JSON.stringify(form))
            .then(() => props.onClose && props.onClose({}, 'escapeKeyDown'))
            .catch(() => setFailed(true));
    }

    return (
        <FormDialog
            {...props}
            title={"Mise à jour de l'organisation"}
        >
            {failed && <Alert severity="error">Erreur lors de la mise à jour de l'organisation</Alert>}
            <FormModel creation={!props.organisation} model={OrganisationFormModel} onRemove={undefined} required={[]} onSubmit={submit} value={props.organisation}>
                <FormTextFieldModel id={'name'} />
                <FormTextFieldModel id={'kv'} />
                <FormTextFieldModel id={'legalForm'} />
                <FormTextFieldModel id={'capitalStockCts'} />
                <FormTextFieldModel id={'phone'} />
                <FormTextFieldModel id={'email'} />
                <FormTextFieldModel id={'website'} />
                <FormTextFieldModel id={'nafCode'} />
                <FormTextFieldModel id={'color'} />
                <FormTextFieldModel id={'logoId'} />
            </FormModel>
        </FormDialog>
    );
}

export default OrganisationDialog;