import React, {useEffect, useState} from "react";
import {makeStyles, MenuItem, Select} from "@material-ui/core";
import {FormModelSelectProps, Institutions} from "../../../interfaces";
import Fetcher, {URLBuild} from "../../../services/fetcher";
import {useTenantId} from "../../../contexts/TenantContext";
import {useForm} from "../index";

interface FormSelectModelProps {
    id: string
}

const useStyles = makeStyles((theme) => ({
    control: {
        flex: 1,
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    label: {
        flex: 1,
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    }
}));

const extractArray = (res: any) =>
    Object.values(res).find(Array.isArray) ?? [];

const FormSelectModel = ({id}: FormSelectModelProps) => {
    const classes = useStyles();
    const tenantId = useTenantId();
    const form = useForm();
    const [select] = useState<FormModelSelectProps>(form.model[id].select ?? {});
    const [data, setData] = useState<{value: string | number, label: string}[] | undefined>(undefined);

    useEffect(() => {
        const fetchData = () => {
            Fetcher.get<Institutions>(URLBuild(select.endpoint ?? '/',
                {
                    pageSize: -1
                }), tenantId)
                .then(r => r.body)
                .then(extractArray)
                .then(r => r.map(cur => ({value: cur[select.value ?? ''], label: cur[select.label ?? '']})))
                .then(r => setData(r));
        }

        if (select.values) {
            setData(Object.entries(select.values).map(([value, label]) => ({value, label})));
        } else if (!!select.endpoint) fetchData();
    }, [tenantId, select]);

    if (data === undefined)
        return null;

    return (
        <>
            <div className={classes.label}>{form.model[id].label}</div>
            <Select
                className={classes.control}
                label={form.model[id].label}
                value={form.form[id] ?? ''}
                id={id}
                placeholder={form.model[id].label}
                onChange={e => form.update(id, form.model[id].type === 'number' ? Number.parseInt(e.target.value as string) : e.target.value as string)}
            >
                {data.map((entry) => (
                    <MenuItem key={entry.value} value={entry.value}>{entry.label}</MenuItem>
                ))}
            </Select>
        </>
    )
}

export default FormSelectModel;
