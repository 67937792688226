import { makeStyles } from '@material-ui/core';
import React from 'react';
import Router from './router';

const useStyles = makeStyles({
  root: {
    display: '1',
    height: '100vh'
  }
});

const App = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Router />
    </div>
  );
}

export default App;
