import { DialogProps } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useState } from "react";
import {Tenant, FormModelObjectProps} from "../../interfaces";
import Fetcher from "../../services/fetcher";
import FormTextFieldModel from "../forms/textfield";
import FormDialog from "../forms/dialog";
import FormModel from "../forms";
import getConfig from "../../configs/AppConfig";

export interface TenantDialogProps extends Omit<DialogProps, 'children'> {
    tenant?: Tenant
}

export const TenantFormModel: FormModelObjectProps = {
    tenantId: {label: 'Tenant ID'},
    name: {label: 'Nom'},
    host: {label: 'Host'},
    owner: {label: 'Email owner', creationOnly: true},
    sddFeeMilli: {label: 'Sdd Fee Milli'},
    sddFixedFeeCts: {label: 'Sdd Fixed Fee Cts'},
    sddFeeRejectedCts: {label: 'Sdd Rejected Fee Cts'},
    bcFeeMilli: {label: 'Blue card Fee Milli'},
    bcFixedFeeCts: {label: 'Blue card Fixed Fee Cts'},
    goCardlessApiToken: {label: 'GoCardless Api Token'}
}

const requiredKeys = [ 'tenantId', 'name', 'host', 'owner' ];

const TenantDialog = (props: TenantDialogProps) => {
    const [failed, setFailed] = useState(false);
    const [loading, setLoading] = useState(false);

    const creating = !props.tenant;

    const submit = (form: any) => {
        setFailed(false);
        setLoading(true);
        if (!!props.tenant) {
            Fetcher.patch(getConfig().ApiUrl + `/nahal/tenants/${props.tenant.tenantId}`, form['tenantId'], JSON.stringify(form))
                .then(() => props.onClose && props.onClose({}, 'escapeKeyDown'))
                .catch(() => setFailed(true))
                .finally(() => setLoading(false));
        } else {
            Fetcher.post(getConfig().ApiUrl + '/nahal/tenants', '', JSON.stringify(form))
                .then(() => props.onClose && props.onClose({}, 'escapeKeyDown'))
                .catch(() => setFailed(true))
                .finally(() => setLoading(false));
        }
    }

    return (
        <FormDialog
            {...props}
            title={(!!props.tenant ? "Mise à jour" : "Création") + " d'un tenant"}
        >
            {failed && <Alert severity="error">Erreur lors de la {!props.tenant ? "création" : "mise à jour"} du tenant</Alert>}
            <FormModel model={TenantFormModel} required={requiredKeys} onSubmit={submit} value={props.tenant} loading={loading} creation={creating}>
                {!props.tenant && <FormTextFieldModel id={'tenantId'} />}
                <FormTextFieldModel id={'name'} />
                <FormTextFieldModel id={'host'} />
                {creating && <FormTextFieldModel id={'owner'} />}
                <FormTextFieldModel id={'sddFeeMilli'} />
                <FormTextFieldModel id={'sddFixedFeeCts'} />
                <FormTextFieldModel id={'sddFeeRejectedCts'} />
                <FormTextFieldModel id={'bcFeeMilli'} />
                <FormTextFieldModel id={'bcFixedFeeCts'} />
                <FormTextFieldModel id={'goCardlessApiToken'} />
            </FormModel>
        </FormDialog>
    );
}

export default TenantDialog;
