import {Backdrop, Dialog, DialogContent, DialogProps, DialogTitle, Divider, makeStyles} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles({
    form: {
        display: 'flex',
        flexDirection: 'column'
    },
});

const FormDialog = (props: DialogProps) => {
    const classes = useStyles();

    return (
        <Dialog
            {...{...props, children: undefined}}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <DialogTitle>{props.title}</DialogTitle>
            <Divider />
            <DialogContent className={classes.form}>
                {props.children}
            </DialogContent>
        </Dialog>
    )
}

export default FormDialog;